@import 'styles/component';
@import 'styles/mui';

body {
  font-family: Nunito, Montserrat, sans-serif !important;
  font-size: 1rem;
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

iframe {
  display: none !important;
}

:root {
  --color-primary-light: #90caf9;
  --color-primary-main: #2196f3;
  --color-primary-dark: #1e88e5;

  --color-secondary-light: #b39ddb;
  --color-secondary-main: #673ab7;
  --color-secondary-dark: #5e35b1;
}
